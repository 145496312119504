import React, { createContext, useContext, useEffect, useState } from 'react';
import { FCWithChildren } from '../../infrastructure/types/global';
import { versionService } from '../services/VersionService';
import { AppVersion } from '../types/version';

interface VersionContextProps {
  version: AppVersion | null;
  loading: boolean;
  error: string | null;
}

const VersionContext = createContext<VersionContextProps | undefined>(undefined);

export const VersionProvider: FCWithChildren = ({ children }) => {
  const [version, setVersion] = useState<AppVersion | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchVersion = async (): Promise<void> => {
      try {
        const version = await versionService.getVersion();
        setVersion(version);
      } catch (err) {
        setError('Failed to fetch version');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchVersion();
  }, []);

  return <VersionContext.Provider value={{ version, loading, error }}>{children}</VersionContext.Provider>;
};

export const useVersion = (): VersionContextProps => {
  const context = useContext(VersionContext);
  if (context === undefined) {
    throw new Error('useVersion must be used within a VersionProvider');
  }
  return context;
};
