export const ENV_CONFIG = {
  env: process.env.REACT_APP_ENV || 'dev',
  logLevel: process.env.REACT_APP_LOG_LEVEL || 'debug',
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://papi.staging.vestiairecollective.com',
  atlasBaseUrl: process.env.REACT_APP_ATLAS_BASE_URL || 'https://apiv2.staging.vestiairecollective.com',
  imagesBaseUrl: process.env.REACT_APP_IMAGES_BASE_URL || 'https://images.staging.vestiairecollective.com',
  cookiesDomain: process.env.REACT_APP_COOKIES_DOMAIN || 'localhost',
  oauthUrl: process.env.REACT_APP_OAUTH_URL || 'https://boauth.preprod.vestiairecollective.com',
  oauthClientCallbackUrl: process.env.REACT_APP_OAUTH_CLIENT_CALLBACK_URL || 'http://localhost:3000/auth/callback',
  oauthClientId: process.env.REACT_APP_OAUTH_CLIENT_ID || 'vc-backoffice',
  oauthClientScopes: process.env.REACT_APP_OAUTH_CLIENT_SCOPES || 'openid email profile',
  toolAuth: process.env.REACT_APP_TOOL_AUTH || 'https://authentication.backoffice.staging.vestiairecollective.com',
};
