import { Avatar, Box, Button, Popover, SxProps, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { theme } from '../../../../theme';
import { useAuth } from '../../../../application/context/AuthContext';
import { useTranslation } from 'react-i18next';

type UserCardProps = {
  src?: string;
  sx?: SxProps;
  topLineText?: string;
  bottomLineText?: string;
  subMenu?: boolean;
};

const UserCard: FC<UserCardProps> = ({ src, sx, topLineText, bottomLineText, subMenu = false }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { logOutAction } = useAuth();
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box sx={{ display: 'flex', ...sx }} onClick={handleClick} aria-describedby={id}>
        <Avatar src={src} sx={{ marginRight: theme.spacing(1) }} />
        <Box>
          <Typography sx={{ lineHeight: '22px', fontWeight: 'bold', wordBreak: 'break-all' }}>{topLineText}</Typography>
          <Typography sx={{ lineHeight: '22px', wordBreak: 'break-all' }}>{bottomLineText}</Typography>
        </Box>
      </Box>
      {subMenu && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box sx={{ p: theme.spacing(1, 2), width: '252px' }}>
            <Button fullWidth={true} variant='outlined' color='error' onClick={() => logOutAction()}>
              {t('SIDEBAR.LOGOUT')}
            </Button>
          </Box>
        </Popover>
      )}
    </>
  );
};

export default UserCard;
