import { Box, Divider, SxProps, Theme, Typography } from '@mui/material';
import { theme } from '../../../../theme';
import { FCWithChildren } from '../../../../infrastructure/types/global';
import { isDefined } from '../../../../utils/type-utils';

type TopBarProps = {
  sx?: SxProps<Theme>;
  message?: string;
  margin?: number;
};

const TopBar: FCWithChildren<TopBarProps> = ({ sx, message, children }) => {
  return (
    <>
      <Box sx={{ p: theme.spacing(3), ...sx }}>
        {isDefined(message) && <Typography variant='h1'>{message}</Typography>}
        {children}
      </Box>
      <Divider />
    </>
  );
};

export default TopBar;
