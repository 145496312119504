import { FC } from 'react';
import { ToasterMessage } from '../../../application/context/ToasterContext';
import { Alert, Snackbar } from '@mui/material';

type BaklavaProps = {
  messageInfo: ToasterMessage | undefined;
  open: boolean;
  onClose: Function;
  onExit: Function;
};

// Do not use that component please use the context
const Baklava: FC<BaklavaProps> = ({ messageInfo, open, onClose, onExit }) => {
  return (
    <Snackbar
      key={messageInfo?.key}
      open={open}
      autoHideDuration={6000}
      onClose={(e, a) => onClose(e, a)}
      TransitionProps={{ onExited: () => onExit() }}
      message={messageInfo?.message}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={() => onClose()} severity={messageInfo?.type} variant='filled' sx={{ width: '100%' }}>
        {messageInfo?.message}
      </Alert>
    </Snackbar>
  );
};

export default Baklava;
