import UserDetails from '../components/Layout/UserDetails/UserDetails';
import { Box } from '@mui/material';

const UserProfilePage = (): JSX.Element => {
  return (
    <Box className='UserProfilePage'>
      <UserDetails />
    </Box>
  );
};

export default UserProfilePage;
