import { UserDto } from '../../application/types/dto/UserDto';
import PapiClient from '../api/PapiClient';

export const getUserByEmail = async (value: string): Promise<UserDto> => {
  return PapiClient.get<UserDto>(`/users/email/${value}`);
};

export const getUserById = async (value: string): Promise<UserDto> => {
  return PapiClient.get<UserDto>(`/users/vcId/${value}`);
};

export const patchBanUser = async (userId: number): Promise<UserDto> => {
  return PapiClient.patch<UserDto, undefined>(`/users/${userId}/bans`);
};

export const patchUnbanUser = async (userId: number): Promise<UserDto> => {
  return PapiClient.patch<UserDto, undefined>(`/users/${userId}/unbans`);
};
