import PapiClient from '../api/PapiClient';
import { VersionDTO } from '../dto/VersionDto';

class VersionRepository {
  public static async getVersion(): Promise<VersionDTO> {
    return PapiClient.get<VersionDTO>('/version');
  }
}

export default VersionRepository;
