import cookie, { type CookieSerializeOptions } from 'cookie';

export const COOKIE_ACCESS_TOKEN = 'access_token';
export const COOKIE_ID_TOKEN = 'id_token';

export const getCookieObject = (): Record<string, string> => {
  return cookie.parse(document.cookie);
};

export const setCookie = (
  key: string,
  value: string | null,
  options?: CookieSerializeOptions,
): Record<string, string> => {
  document.cookie = cookie.serialize(key, value ?? '', options);
  return getCookieObject();
};

export const getCookie = (key: string): string => {
  return getCookieObject()[key] ?? null;
};

export const removeCookie = (key: string): Record<string, string> => {
  return setCookie(key, null, { expires: new Date(0) });
};
