export interface MessageModeration {
  required: boolean;
  outcome: ModerationOutcome | null;
}

export enum ModerationOutcome {
  SCAM = 'SCAM',
  CIRCUM = 'CIRCUM',
  SAFE = 'SAFE',
}
