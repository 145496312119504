import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FCWithChildren } from '../../infrastructure/types/global';
import { Channel } from '../types/Channel';
import { channelService } from '../services/ChannelService';
import { isDefined, isEmptyString } from '../../utils/type-utils';
import { socket, SOCKET_MESSAGE_DETAILS_EVENT } from '../../infrastructure/websocket/socket';
import { MessageFromSocket } from '../types/Message';
import ApiError from '../services/error/ErrorService';
import { ApplicationRoutes } from '../constants/navigation';

export enum ConversationSide {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

interface ChannelContextProps {
  channel: Channel | null;
  providerId: string | undefined;
  inReadonlyMode: boolean;
  loading: boolean;
  error: string | null;
  sideChecked: ConversationSide | null;
  handleMessageCheck: (isSeller: boolean, checked: boolean, messageId: number) => void;
  selectedMessages: number[];
}

const ChannelContext = createContext<ChannelContextProps | undefined>(undefined);

export const ChannelProvider: FCWithChildren = ({ children }) => {
  const params = useParams<{ '*': string }>();
  const [providerId, setProviderId] = useState<string | undefined>(
    isEmptyString(params['*']) ? undefined : params['*'],
  );
  const [channel, setChannel] = useState<Channel | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedMessages, setSelectedMessages] = useState<number[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [sideChecked, setSideChecked] = useState<ConversationSide | null>(null);
  const navigate = useNavigate();

  const inReadonlyMode = isDefined(providerId);

  useEffect(() => {
    socket.connect();

    return (): void => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    function onNewMessage(value: MessageFromSocket): void {
      if (!isDefined(channel) || channel.channelId != value.channelId.toString()) {
        return;
      }

      channel.messages.push(value);
      setChannel({ ...channel });
    }

    socket.on(SOCKET_MESSAGE_DETAILS_EVENT, onNewMessage);

    return (): void => {
      socket.off(SOCKET_MESSAGE_DETAILS_EVENT, onNewMessage);
    };
  }, [channel]);

  useEffect(() => {
    setProviderId(isEmptyString(params['*']) ? undefined : params['*']);
    const fetchChannel = async (): Promise<void> => {
      try {
        const fetchedChannel = await channelService.getChannelById(providerId);
        setChannel(fetchedChannel);
      } catch (err: unknown) {
        if (err instanceof ApiError && err.status === 404) {
          navigate(ApplicationRoutes.NO_RESULTS);
        } else {
          setError('Failed to fetch channel');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchChannel();
  }, [providerId, navigate, params]);

  const handleMessageCheck = (isSeller: boolean, checked: boolean, messageId: number): void => {
    const updatedSelectedMessagesSet = new Set(selectedMessages);

    if (checked) {
      updatedSelectedMessagesSet.add(messageId);
    } else {
      updatedSelectedMessagesSet.delete(messageId);
    }

    setSelectedMessages(Array.from(updatedSelectedMessagesSet));
    setSideChecked(
      updatedSelectedMessagesSet.size === 0 ? null : isSeller ? ConversationSide.SELLER : ConversationSide.BUYER,
    );
  };

  return (
    <ChannelContext.Provider
      value={{ channel, providerId, inReadonlyMode, loading, error, handleMessageCheck, sideChecked, selectedMessages }}
    >
      {children}
    </ChannelContext.Provider>
  );
};

export const useChannel = (): ChannelContextProps => {
  const context = useContext(ChannelContext);
  if (!isDefined(context)) {
    throw new Error('useChannel must be used within a ChannelProvider');
  }
  return context;
};
