import { ChannelDto } from '../../application/types/dto/ChannelDto';
import { isDefined } from '../../utils/type-utils';
import PapiClient from '../api/PapiClient';

class ChannelRepository {
  public static async getChannelById(id?: string): Promise<ChannelDto> {
    if (isDefined(id)) {
      return PapiClient.get(`/channels/providerId/${id}`);
    }
    return PapiClient.get('/channels/next');
  }
}

export default ChannelRepository;
