import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ErrorProps {
  errorCode?: number;
}

const ErrorComponent: FC<ErrorProps> = ({ errorCode = 500 }) => {
  const { t } = useTranslation();

  return (
    <Box textAlign='center' paddingTop={20}>
      {(errorCode === 401 || errorCode === 403) && (
        <>
          <Typography variant='h6' paragraph={true}>
            {t('ERROR.PAGE.403.TITLE')}
          </Typography>

          <Typography variant='body2'>{t('ERROR.PAGE.403.MESSAGE')}</Typography>
        </>
      )}
      {errorCode === 404 && (
        <>
          <Typography variant='h6' paragraph={true}>
            {t('ERROR.PAGE.404.TITLE')}
          </Typography>

          <Typography variant='body2'>{t('ERROR.PAGE.404.MESSAGE')}</Typography>
        </>
      )}
      {errorCode === 500 && (
        <>
          <Typography variant='h6' paragraph={true}>
            Something went wrong!
          </Typography>

          <Typography variant='body2'>An unknown error occurred while loading this page.</Typography>
        </>
      )}
    </Box>
  );
};

export default ErrorComponent;
