import { FC } from 'react';
import { Typography } from '@mui/material';
import { theme } from '../../../../theme';
import { User } from '../../../../core/entities/User';
import { useTranslation } from 'react-i18next';

type UserDetailsTableProps = {
  userInfos?: User;
};

const UserDetailsTable: FC<UserDetailsTableProps> = ({ userInfos }) => {
  const { t } = useTranslation();

  return (
    <table style={{ width: '100%', border: `1px solid ${theme.palette.grey[300]}` }}>
      <tbody>
        <tr>
          {/* <td style={{border: 'inherit', padding: '10px'}}>
            <Typography sx={{
                fontSize:theme.typography.body2.fontSize,
                color: theme.palette.textBlackColor.light,
            }}>Name</Typography>
            <Typography>Not yet</Typography>
        </td> */}
          <td style={{ border: 'inherit', padding: '10px' }}>
            <Typography
              sx={{
                fontSize: theme.typography.body2.fontSize,
                color: theme.palette.textBlackColor.light,
              }}
            >
              {t('USER.DETAILS.USERNAME')}
            </Typography>
            <Typography>{userInfos?.username}</Typography>
          </td>
          {/* <td style={{border: 'inherit', padding: '10px'}}>
            <Typography sx={{
                fontSize:theme.typography.body2.fontSize,
                color: theme.palette.textBlackColor.light
            }}>Location</Typography>
            <Typography>Not yet</Typography>
        </td> */}
        </tr>
      </tbody>
    </table>
  );
};

export default UserDetailsTable;
