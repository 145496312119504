export interface Member {
  id: number;
  vcId: string;
  role: MemberRole;
  username: string;
  email: string;
  profilePictureUrl: string;
}

export enum MemberRole {
  SELLER = 'SELLER',
  BUYER = 'BUYER',
}
