import { useVersion, VersionProvider } from '../../../application/context/VersionContext';
import styles from './VersionPage.module.css';

const VersionPage = (): JSX.Element => {
  return (
    <VersionProvider>
      <VersionDisplay />
    </VersionProvider>
  );
};

const VersionDisplay = (): JSX.Element => {
  const { version } = useVersion();

  return (
    <div className={styles.container}>
      <p className={styles.versionText}>API version: {version?.backend}</p>
    </div>
  );
};

export default VersionPage;
