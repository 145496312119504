import ChannelRepository from '../../infrastructure/repositories/ChannelRepository';
import { Channel } from '../types/Channel';

class ChannelService {
  public async getChannelById(id?: string): Promise<Channel> {
    const { channelId, providerId, createdById, createdAt, productId, productPath, productPicture, members, messages } =
      await ChannelRepository.getChannelById(id);

    return {
      channelId,
      providerId,
      createdById,
      createdAt,
      productId,
      productPath,
      productPicture,
      members,
      messages: messages.map((message) => ({ ...message, isSelected: false })),
    };
  }
}

export const channelService = new ChannelService();
