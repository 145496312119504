import { Box, SvgIcon, Theme } from '@mui/material';
import { FC } from 'react';
import { theme } from '../../../../../theme';
import CheckBox from '@mui/material/Checkbox';
import { ConversationSide, useChannel } from '../../../../../application/context/ChannelContext';
import { Message } from '../../../../../application/types/Message';
import { ModerationOutcome } from '../../../../../application/types/MessageModeration';

type ConversationsChatMessageProps = {
  isSeller: boolean;
  message: Message;
};

const DEFAULT_BORDER_RADIUS = '4px';

const getStyles = (
  theme: Theme,
  isSeller: boolean,
  moderationOutcome: ModerationOutcome | null,
  isHighlighted: boolean,
  isSelected: boolean,
): {
  backgroundColor: string;
  borderColor: string;
  borderRadius: string;
  showWarning: boolean;
} => {
  let backgroundColor = isSeller ? theme.palette.notificationColor.light : theme.palette.notificationColor.main;
  let borderColor = theme.palette.grey[300];
  let borderRadius = DEFAULT_BORDER_RADIUS;
  let showWarning = false;

  if (isHighlighted) {
    backgroundColor = theme.palette.warning.light;
    borderColor = theme.palette.warning.main;
    borderRadius = isSeller ? '12px 12px 1px 12px' : '12px 12px 12px 1px';
    showWarning = true;
  }
  if (moderationOutcome === ModerationOutcome.SCAM || moderationOutcome === ModerationOutcome.CIRCUM) {
    backgroundColor = theme.palette.error.light;
    borderColor = theme.palette.error.main;
    showWarning = false;
  }
  if (moderationOutcome === ModerationOutcome.SAFE) {
    backgroundColor = theme.palette.success.light;
    borderColor = theme.palette.success.main;
    showWarning = false;
  }
  if (isSelected) {
    backgroundColor = theme.palette.selectedColor.light;
    borderColor = theme.palette.selectedColor.main;
    borderRadius = DEFAULT_BORDER_RADIUS;
    showWarning = false;
  }

  return { backgroundColor, borderColor, borderRadius, showWarning };
};

const ConversationsChatMessage: FC<ConversationsChatMessageProps> = ({ message, isSeller }) => {
  const context = useChannel();
  const isHighlighted = message.moderation?.required || false;
  const messageText = message.text;
  const moderationOutcome = message.moderation?.outcome || null;

  const isDisabled =
    (isSeller && context.sideChecked === ConversationSide.BUYER) ||
    (!isSeller && context.sideChecked === ConversationSide.SELLER);

  const styles = getStyles(theme, isSeller, moderationOutcome, isHighlighted, message.isSelected);
  const { backgroundColor, borderColor, borderRadius, showWarning } = styles;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: isSeller ? 'row-reverse' : 'row',
        gap: theme.spacing(1),
        marginBottom: theme.spacing(2),
      }}
    >
      {!context.inReadonlyMode && (
        <CheckBox
          disabled={isDisabled}
          onChange={(e, checked) => context.handleMessageCheck(isSeller, checked, message.id)}
        />
      )}
      <Box
        sx={{
          backgroundColor,
          border: `1px solid ${borderColor}`,
          borderRadius,
          display: 'inline',
          maxWidth: '80%',
          padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        }}
      >
        {messageText}
      </Box>
      {showWarning && (
        <SvgIcon
          sx={{
            fill: theme.palette.warning.main,
          }}
        >
          <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
            <path d='M9.83497 4.24951C10.7972 2.58285 13.2028 2.58284 14.1651 4.24951L21.7428 17.3745C22.7051 19.0412 21.5023 21.1245 19.5778 21.1245H4.42231C2.49781 21.1245 1.29499 19.0412 2.25724 17.3745L9.83497 4.24951ZM13.2991 4.74951C12.7217 3.74951 11.2783 3.74951 10.701 4.74951L3.12327 17.8745C2.54592 18.8745 3.26761 20.1245 4.42231 20.1245H19.5778C20.7325 20.1245 21.4541 18.8745 20.8768 17.8745L13.2991 4.74951Z' />
            <path d='M12.75 17.2495C12.75 17.6637 12.4142 17.9995 12 17.9995C11.5858 17.9995 11.25 17.6637 11.25 17.2495C11.25 16.8353 11.5858 16.4995 12 16.4995C12.4142 16.4995 12.75 16.8353 12.75 17.2495Z' />
            <path d='M11.35 14.9995V8.62451H12.65V14.9995H11.35Z' />
          </svg>
        </SvgIcon>
      )}
    </Box>
  );
};

export default ConversationsChatMessage;
