import { Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from '../../../../../theme';

type ConversationsChatTimestampProps = {
  timeToDisplay: string;
};

const ConversationsChatTimestamp: FC<ConversationsChatTimestampProps> = ({ timeToDisplay }) => {
  return (
    <Typography
      sx={{
        fontSize: '10px',
        color: '#ABABAB',
        lineHeight: '14px',
        textAlign: 'center',
        marginBottom: theme.spacing(1),
      }}
    >
      {timeToDisplay}
    </Typography>
  );
};

export default ConversationsChatTimestamp;
