import { Box, Link } from '@mui/material';
import { FC } from 'react';
import { theme } from '../../../../theme';
import { useChannel } from '../../../../application/context/ChannelContext';

const ConversationsItem: FC = () => {
  const { channel } = useChannel();

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Link href={channel?.productPath} target='_blank'>
          <img
            style={{
              maxHeight: '80px',
              width: '80px',
              marginRight: theme.spacing(2),
            }}
            alt='product-image'
            src={channel?.productPicture}
          />
        </Link>
        <Box>
          {/* <Typography variant='body2' fontWeight='700' color='black'>
            Prada
          </Typography>
          <Typography variant='body2'>Re-edition Handbag</Typography>
          <Typography variant='body2'>36</Typography>
          <Typography variant='body2' fontWeight='500' color='black'>
            200$
          </Typography> */}
          <Link href={channel?.productPath} target='_blank'>
            {channel?.productId}
          </Link>
        </Box>
      </Box>
      {/* <Typography marginTop={theme.spacing(1)} color={theme.palette.secondary.main}>
        {t('CONVERSATIONS.SIDEBAR.TRACKINGNUMBER')}
        <span style={{ color: 'black' }}> 123456</span>
      </Typography> */}
    </Box>
  );
};

export default ConversationsItem;
