import PKCE from 'js-pkce';
import Cookies from 'cookies-ts';
import { ENV_CONFIG } from './AuthService.config';
import { getCookie } from '../../../infrastructure/persistence/CookieAdapter';
import { jwtDecode } from 'jwt-decode';
import { Auth, IdToken } from '../../../infrastructure/types/global';
import { isDefined } from '../../../utils/type-utils';
import ITokenResponse from 'js-pkce/dist/ITokenResponse';
import PapiClient from '../../../infrastructure/api/PapiClient';

interface Token extends ITokenResponse {
  id_token: string;
}

class AuthenticationService {
  private readonly COOKIE_ACCESS_TOKEN = 'access_token';
  private readonly COOKIE_ID_TOKEN = 'id_token';
  private readonly _cookies: Cookies;
  private readonly _auth: PKCE;
  private authSaved: Auth | undefined;

  constructor() {
    this.authSaved = undefined;
    this._cookies = new Cookies();
    this._auth = this.initializePKCE();
  }

  private initializePKCE(): PKCE {
    const pKCE = new PKCE({
      client_id: ENV_CONFIG.oauthClientId,
      redirect_uri: ENV_CONFIG.oauthClientCallbackUrl,
      authorization_endpoint: `${ENV_CONFIG.oauthUrl}/oauth2/auth`,
      token_endpoint: `${ENV_CONFIG.oauthUrl}/oauth2/token`,
      requested_scopes: ENV_CONFIG.oauthClientScopes,
    });

    return pKCE;
  }

  private removeCookies(): void {
    this._cookies.remove(this.COOKIE_ID_TOKEN, {
      domain: ENV_CONFIG.cookiesDomain,
    });
    this._cookies.remove(this.COOKIE_ACCESS_TOKEN, {
      domain: ENV_CONFIG.cookiesDomain,
    });
  }

  private removeSessionStorage(): void {
    sessionStorage.removeItem(this.COOKIE_ID_TOKEN);
    sessionStorage.removeItem(this.COOKIE_ACCESS_TOKEN);
  }

  async authorise(): Promise<void> {
    if (!isDefined(this.accessToken) || !isDefined(this.idToken)) {
      return;
    }

    const decodedToken = jwtDecode<IdToken>(this.idToken);
    const isAuthenticated = decodedToken.exp ? new Date() <= new Date(decodedToken.exp * 1000) : false;
    const isAuthorized = isAuthenticated && isDefined(this.accessToken);
    const isModerator = await PapiClient.authoriseForModerators({
      accessToken: this.accessToken,
      idToken: decodedToken,
    });

    this.authSaved = {
      isAuthenticated,
      accessToken: this.accessToken,
      idToken: decodedToken,
      isAuthorized,
      isModerator,
    };

    return;
  }

  async handleCallback(): Promise<void> {
    try {
      const tokenResponse = (await this._auth.exchangeForAccessToken(window.location.toString())) as Token;
      this.storeTokens(tokenResponse);
    } catch (error) {
      console.error('Error exchanging code for tokens:', error);
    }
  }

  private storeTokens(token: Token): void {
    this._cookies.set(this.COOKIE_ACCESS_TOKEN, token.access_token, {
      expires: `${token.expires_in}s`,
      domain: ENV_CONFIG.cookiesDomain,
    });
    this._cookies.set(this.COOKIE_ID_TOKEN, token.id_token, {
      expires: `${token.expires_in}s`,
      domain: ENV_CONFIG.cookiesDomain,
    });

    sessionStorage.setItem(this.COOKIE_ACCESS_TOKEN, token.access_token);
    sessionStorage.setItem(this.COOKIE_ID_TOKEN, token.id_token);
  }

  logout(): void {
    this.removeCookies();
    this.removeSessionStorage();
    this.authSaved = undefined;
  }

  isAuthentificated(): boolean {
    return isDefined(this.authSaved) && this.authSaved.isAuthenticated;
  }

  isAuthentificatedAndAllowed(): boolean {
    return (
      isDefined(this.authSaved) &&
      this.authSaved.isAuthenticated &&
      this.authSaved.isAuthorized &&
      this.authSaved.isModerator
    );
  }

  get auth(): Auth | undefined {
    return this.authSaved;
  }

  private get idToken(): string | null | undefined {
    if (process.env.NODE_ENV === 'development') {
      return process.env.REACT_APP_ID_TOKEN;
    }
    return getCookie(this.COOKIE_ID_TOKEN);
  }

  private get accessToken(): string | null | undefined {
    if (process.env.NODE_ENV === 'development') {
      return process.env.REACT_APP_ACCESS_TOKEN;
    }
    return getCookie(this.COOKIE_ACCESS_TOKEN);
  }
}

export const authService = new AuthenticationService();
