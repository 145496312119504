import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./assets/i18n/en.json";
import translationFR from "./assets/i18n/fr.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng:
      process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test"
        ? false
        : "en",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
