import { User } from '../../core/entities/User';
import {
  getUserByEmail,
  getUserById,
  patchBanUser,
  patchUnbanUser,
} from '../../infrastructure/repositories/UserRepository';
import { stringToDate } from '../../utils/date-utils';
import { SearchKeywordType, typeToSearch } from '../../utils/search-utils';
import { isDefined } from '../../utils/type-utils';
import { UserDto } from '../types/dto/UserDto';

export const getUser = async (userToSearch?: string): Promise<User> => {
  if (!isDefined(userToSearch)) {
    throw new Error('Invalid value');
  }

  const searchEmail = typeToSearch(userToSearch) === SearchKeywordType.EMAIL;

  const response = searchEmail ? getUserByEmail(userToSearch) : getUserById(userToSearch);

  return mapUserResponse(response);
};

export const banUser = async (userToBan?: User): Promise<User> => {
  if (!isDefined(userToBan)) {
    throw new Error('Invalid value');
  }

  const response = patchBanUser(userToBan.id);

  return mapUserResponse(response);
};

export const unbanUser = async (userToUnban?: User): Promise<User> => {
  if (!isDefined(userToUnban)) {
    throw new Error('Invalid value');
  }

  const response = patchUnbanUser(userToUnban.id);

  return mapUserResponse(response);
};

const mapUserResponse = async (response: Promise<UserDto>): Promise<User> => {
  const responseData = await response;
  const banExpiryDate = isDefined(responseData.banExpiresAt) ? stringToDate(responseData.banExpiresAt) : undefined;

  const mappedResponse: User = await {
    ...responseData,
    banExpiryDate,
  };

  return mappedResponse;
};
