import { io } from 'socket.io-client';

export const SOCKET_BANNING_DETAILS_EVENT = 'user.banning.details';
export const SOCKET_MESSAGE_DETAILS_EVENT = 'message.details';
export const SOCKET_CHANNEL_DETAILS_EVENT = 'channel.details';

export const socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
  autoConnect: false,
  path: '/websocket',
  transports: ['websocket'],
});
