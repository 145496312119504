import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationRoutes } from '../../application/constants/navigation';

const HomePage = (): JSX.Element => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(ApplicationRoutes.CONVERSATIONS_ALL);
  }, [navigate]);

  return <></>;
};

export default HomePage;
