import { alpha, createTheme } from '@mui/material';
import { green, grey, orange, purple, red } from '@mui/material/colors';

// https://mui.com/material-ui/customization/palette/#generate-tokens-using-augmentcolor-utility
declare module '@mui/material/styles' {
  interface Palette {
    notificationColor: Palette['primary'];
    blueOnHoverColor: Palette['primary'];
    processedStatusColor: Palette['primary'];
    processingStatusColor: Palette['primary'];
    scheduledStatusColor: Palette['primary'];
    selectedColor: Palette['primary'];
    textBlackColor: Palette['primary'];
  }

  interface PaletteOptions {
    notificationColor?: Palette['primary'];
    blueOnHoverColor?: Palette['primary'];
    processedStatusColor?: Palette['primary'];
    processingStatusColor?: Palette['primary'];
    scheduledStatusColor?: Palette['primary'];
    selectedColor?: Palette['primary'];
    textBlackColor?: PaletteOptions['primary'];
  }
}

const font = '"Helvetica Now Text", Helvetica, Arial, sans-serif';
const blue = '#1976d2';

export let theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
});

theme = createTheme(theme, {
  typography: {
    fontFamily: font,
    body1: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      color: '#666',
      fontWeight: 400,
      lineHeight: '20px',
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 700,
    },
    h1: {
      fontSize: 24,
      color: 'black',
      fontWeight: 700,
      lineHeight: '35.28px',
    },
    h2: {
      fontSize: 18,
      color: 'black',
      fontWeight: 500,
      lineHeight: '32px',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: font,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: font,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: font,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: font,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'black' },
          style: {
            border: '1px solid black',
            backgroundColor: 'black',
            color: 'white',
            '&:hover': {
              backgroundColor: grey[800],
            },
            '&:active': {
              backgroundColor: grey[900],
            },
            '&:disabled': {
              borderColor: grey[100],
              backgroundColor: grey[50],
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontFamily: font,
          variants: [
            {
              props: { variant: 'outlined', color: 'primary' },
              style: {
                backgroundColor: alpha(blue, 0.11),
                '&:hover': {
                  backgroundColor: alpha(blue, 0.21),
                },
                '&:active': {
                  backgroundColor: alpha(blue, 0.31),
                },
              },
            },
            {
              props: { variant: 'outlined', color: 'secondary' },
              style: {
                border: '1px solid #e3e3e3',
                color: 'black',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  borderColor: 'black',
                },
                '&:active': {
                  backgroundColor: 'rgba(0, 0, 0, 0.10)',
                },
              },
            },
            {
              props: { variant: 'outlined', color: 'info' },
              style: {
                border: '1px solid #e3e3e3',
                color: 'black',
                backgroundColor: '#F5F5F5',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.10)',
                  borderColor: 'black',
                },
                '&:active': {
                  backgroundColor: 'rgba(0, 0, 0, 0.10)',
                },
              },
            },
            {
              props: { variant: 'outlined', color: 'error' },
              style: {
                backgroundColor: '#FEF6F6',
                '&:hover': {
                  backgroundColor: red[50],
                },
                '&:active': {
                  backgroundColor: red[100],
                },
              },
            },
            {
              props: { variant: 'outlined', color: 'success' },
              style: {
                backgroundColor: '#F2FDF2',
                '&:hover': {
                  backgroundColor: green[50],
                },
                '&:active': {
                  backgroundColor: green[100],
                },
              },
            },
          ],
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FAFAFA',
        },
      },
    },
  },
  palette: {
    success: {
      main: green[500],
      light: '#F2FDF2',
    },
    error: {
      main: red[500],
      light: '#FEF6F6',
    },
    warning: {
      main: orange[500],
      light: '#FFF7F0',
    },
    textBlackColor: theme.palette.augmentColor({
      color: {
        main: alpha('#000', 0.7),
      },
      name: 'textBlackColor',
    }),
    scheduledStatusColor: theme.palette.augmentColor({
      color: {
        main: alpha('#9c27b0cc', 0.12),
        contrastText: '#000',
      },
      name: 'scheduledStatusColor',
    }),
    processingStatusColor: theme.palette.augmentColor({
      color: {
        main: alpha('#0288d1cc', 0.12),
        contrastText: '#000',
      },
      name: 'processingStatusColor',
    }),
    processedStatusColor: theme.palette.augmentColor({
      color: {
        main: alpha('#2e7d32cc', 0.12),
        contrastText: '#000',
      },
      name: 'processedStatusColor',
    }),
    notificationColor: theme.palette.augmentColor({
      color: {
        main: '#F5F5F5',
        light: '#FFF',
        contrastText: '#000',
      },
      name: 'notificationColor',
    }),
    blueOnHoverColor: theme.palette.augmentColor({
      color: {
        main: alpha(blue, 0.21),
        light: alpha(blue, 0.08),
      },
      name: 'blueOnHoverColor',
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: '#666',
        contrastText: '#000',
      },
      name: 'secondary',
    }),
    selectedColor: theme.palette.augmentColor({
      color: {
        light: '#F3F3FC',
        main: purple[900],
      },
      name: 'selectedColor',
    }),
  },
});
