import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import ConversationsBox from './ConversationsBox';
import ConversationsSideBar from './ConversationsSideBar';
import { useNavigate } from 'react-router-dom';
import { useChannel } from '../../../../application/context/ChannelContext';
import { ApplicationRoutes } from '../../../../application/constants/navigation';
import { theme } from '../../../../theme';

const ConversationsLayout: FC = () => {
  const navigate = useNavigate();
  const { error, loading } = useChannel();

  if (error) {
    navigate(ApplicationRoutes.NO_RESULTS);
  }

  return (
    <>
      {loading ? (
        <Typography>is loading</Typography>
      ) : (
        <Box
          sx={{
            padding: theme.spacing(2),
            background: theme.palette.grey[50],
            height: '100%',
          }}
        >
          <Box
            sx={{
              background: 'white',
              border: `1px solid ${theme.palette.grey[300]}`,
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'row',
              height: 'calc(100% - 84px)',
              overflow: 'scroll',
            }}
          >
            <ConversationsSideBar />
            <ConversationsBox />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ConversationsLayout;
