import { FCWithChildren } from '../../../../infrastructure/types/global';
import clsx from 'clsx';
import styles from './MainSection.module.scss';
import { styled } from '@mui/material';

export type MainSectionProps = {
  containerClassName?: string;
  drawerWidth: number;
  open: boolean;
};

const MainSection: FCWithChildren<MainSectionProps> = ({ containerClassName, drawerWidth, open, children }) => {
  const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open',
  })<{
    open?: boolean;
  }>(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    variants: [
      {
        props: ({ open }): boolean => !!open,
        style: {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: `${drawerWidth}px`,
        },
      },
    ],
  }));

  return (
    <Main open={open} className={clsx(styles.pageContainer, containerClassName)}>
      {children}
    </Main>
  );
};

export default MainSection;
