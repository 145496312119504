import { createContext, useContext, useEffect, useState } from 'react';
import { Auth, FCWithChildren } from '../../infrastructure/types/global';
import { authService } from '../services/auth/AuthService';
import { isDefined } from '../../utils/type-utils';

export type AuthContextProps = {
  auth: Auth | undefined;
  isLoading: boolean;
  loginAction: Function;
  logOutAction: Function;
};

const AuthContext = createContext<AuthContextProps>({} as never);

export const AuthProvider: FCWithChildren = ({ children }): React.JSX.Element => {
  const [auth, setAuth] = useState<Auth>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    authService
      .authorise()
      .then(() => {
        setAuth(authService.auth);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error('Auth error');
        console.error(e);
        setIsLoading(false);
      });
  }, []);

  const loginAction = (): void => {
    if (!isDefined(process.env.REACT_APP_BACKOFFICE_URL)) {
      throw new Error('REACT_APP_BACKOFFICE_URL is not defined');
    }

    window.location.href = process.env.REACT_APP_BACKOFFICE_URL;
  };

  const logOutAction = (): void => {
    authService.logout();
    setAuth(undefined);
  };

  return <AuthContext.Provider value={{ auth, isLoading, loginAction, logOutAction }}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextProps => useContext(AuthContext);
