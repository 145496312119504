import { Box, Button, Divider, SxProps, Theme, ThemeProvider } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../theme';
import { useChannel } from '../../../../application/context/ChannelContext';

const boxtyles: SxProps<Theme> = {
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'flex-end',
  p: `${theme.spacing(2)} ${theme.spacing(4)}`,
};

const ConversationsButtons: FC = () => {
  const { t } = useTranslation();
  const context = useChannel();

  const unselectedButtons = (
    <Box sx={boxtyles}>
      <Button variant='outlined' color='info'>
        {t('CONVERSATIONS.CTA.SKIP')}
      </Button>
    </Box>
  );

  const selectedButtons = (
    <ThemeProvider theme={theme}>
      <Box sx={boxtyles}>
        <Button variant='outlined' color='error'>
          {t('CONVERSATIONS.CTA.OTHER')}
        </Button>
        <Button variant='outlined' color='error'>
          {t('CONVERSATIONS.CTA.SCAM')}
        </Button>
        <Button variant='outlined' color='error'>
          {t('CONVERSATIONS.CTA.CIRCUMVENTION')}
        </Button>
        <Button variant='outlined' color='success'>
          {t('CONVERSATIONS.CTA.SAFE')}
        </Button>
      </Box>
      <Divider />
      <Box sx={boxtyles}>
        <Button variant='black'>{t('CONVERSATIONS.CTA.CONTINUE')}</Button>
      </Box>
    </ThemeProvider>
  );

  return context.selectedMessages.length ? selectedButtons : unselectedButtons;
};

export default ConversationsButtons;
