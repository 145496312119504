import VersionRepository from '../../infrastructure/repositories/VersionRepository';
import { AppVersion } from '../types/version';

class VersionService {
  public async getVersion(): Promise<AppVersion> {
    const backendVersion = (await VersionRepository.getVersion()).version;
    return {
      backend: backendVersion,
      frontend: process.env.REACT_APP_VERSION || '0.0.0',
    };
  }
}

export const versionService = new VersionService();
