import { Box, Button, Drawer, List, ListItem, styled, Typography } from '@mui/material';
import { FC } from 'react';
import UserCard from '../UserCard/UserCard';
import SearchBar from '../SearchBar/SearchBar';
import { theme } from '../../../../theme';
import { useAuth } from '../../../../application/context/AuthContext';
import { isDefined } from '../../../../utils/type-utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ArrowForwardIos } from '@mui/icons-material';
import './SideBar.css';
import { ApplicationRoutes } from '../../../../application/constants/navigation';

type SideBarProps = {
  open: boolean;
  drawerWidth: number;
};

const SideBar: FC<SideBarProps> = ({ open, drawerWidth }) => {
  const { t, i18n } = useTranslation();

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    paddingBottom: 0,
  }));

  const handleLanguageSwap = (): void => {
    i18n.changeLanguage(i18n.language !== 'en' ? 'en' : 'de');
  };

  const { auth, loginAction } = useAuth();

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      className='Sidebar'
      variant='persistent'
      anchor='left'
      open={open}
    >
      <DrawerHeader>
        {isDefined(auth) && auth.isAuthenticated ? (
          <>
            <UserCard
              src=''
              sx={{
                backgroundColor: 'white',
                border: '1px solid white',
                borderRadius: '5px',
                cursor: 'pointer',
                padding: theme.spacing(3),
                '&:hover': {
                  backgroundColor: theme.palette.blueOnHoverColor.light,
                  borderColor: theme.palette.blueOnHoverColor.main,
                },
              }}
              topLineText={auth.idToken.given_name}
              bottomLineText={auth.idToken.family_name}
              subMenu={true}
            />
            {auth.isAuthorized && <SearchBar sx={{ marginTop: theme.spacing(2) }} />}
          </>
        ) : (
          <Button variant='outlined' onClick={() => loginAction()}>
            Login
          </Button>
        )}
      </DrawerHeader>
      <Box sx={{ padding: theme.spacing(4) }}>
        <Typography variant='body2'>{t('MENU.MODERATION')}</Typography>
        <List>
          <Link to={ApplicationRoutes.CONVERSATIONS_ALL}>
            <ListItem className='Sidebar-listItem'>
              {t('MENU.CONVERSATIONS')} <ArrowForwardIos fontSize='small' />
            </ListItem>
          </Link>
        </List>
      </Box>
      {(process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') && (
        <Button
          className='SideBar-KeyModeCTA'
          onClick={() => {
            handleLanguageSwap();
          }}
        >
          Key Mode
        </Button>
      )}
    </Drawer>
  );
};

export default SideBar;
