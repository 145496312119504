import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { theme } from '../../theme';
import TopBar from '../components/Layout/TopBar/TopBar';

const UserDetailsNotFound: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <TopBar message={t('USER.NOTFOUND.HEADER')} />
      <Box
        sx={{
          p: theme.spacing(3),
          height: 'calc(100% - 84px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: theme.spacing(3),
          textAlign: 'center',
        }}
      >
        <Box
          component='img'
          sx={{
            height: 66,
            width: 55,
          }}
          alt={t('USER.NOTFOUND.TITLE')}
          src={`${process.env.PUBLIC_URL}/images/no-results.svg`}
        />
        <Typography fontSize={theme.typography.h5.fontSize}>{t('USER.NOTFOUND.TITLE')}</Typography>
        <Typography sx={{ width: '330px' }}>{t('USER.NOTFOUND.TEXT')}</Typography>
      </Box>
    </>
  );
};

export default UserDetailsNotFound;
