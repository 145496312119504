import { Box } from '@mui/material';
import { FC } from 'react';
import ConversationsChatMessage from './ConversationsChatMessage';
import ConversationsChatTimestamp from './ConversationsChatTimestamp';
import { theme } from '../../../../../theme';
import { useChannel } from '../../../../../application/context/ChannelContext';
import { isDefined } from '../../../../../utils/type-utils';

const ConversationsChat: FC = () => {
  const { channel, selectedMessages, error: channelError } = useChannel();

  if (isDefined(channelError)) {
    return <div>Error: {channelError}</div>;
  } else if (!isDefined(channel)) {
    return <div>Loading..</div>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
      }}
    >
      <ConversationsChatTimestamp timeToDisplay='05.12.2024' />
      {channel.messages.map((message, index) => (
        <ConversationsChatMessage
          key={index}
          isSeller={message.senderId === channel.createdById}
          message={{
            ...message,
            isSelected: selectedMessages.includes(message.id),
          }}
        />
      ))}
    </Box>
  );
};

export default ConversationsChat;
