import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from '../../../../theme';
import UserCard from '../UserCard/UserCard';
import { Member } from '../../../../application/types/Member';

type ConversationsMemberProps = {
  title: string;
  color: string;
  member: Member;
};

const ConversationsMember: FC<ConversationsMemberProps> = ({ title, color, member }) => {
  return (
    <Box
      sx={{
        backgroundColor: color,
        borderRadius: '4px',
        border: `1px solid ${theme.palette.grey[300]}`,
        marginBottom: theme.spacing(1),
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      }}
    >
      <Typography>
        {title}:{' '}
        <u>
          <b>{member.vcId}</b>
        </u>
      </Typography>
      <UserCard
        src={member.profilePictureUrl}
        sx={{ backgroundColor: 'transparent', margin: `${theme.spacing(1)} 0` }}
        topLineText={member.username}
        bottomLineText={member.username}
      />
      {/* <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', color: 'black' }}>
        <LocationOnIcon color='secondary' sx={{ fontSize: 16 }} />
        London
      </Typography> */}
    </Box>
  );
};

export default ConversationsMember;
