export const RouteParts = {
  WILDCARD: '*',
  CONVERSATIONS: 'conversations',
  USERS: 'users',
};

export const ApplicationRoutes = {
  ROOT: '/',
  CONVERSATION_SINGLE: `/${RouteParts.CONVERSATIONS}/${RouteParts.WILDCARD}`,
  CONVERSATIONS_ALL: `/${RouteParts.CONVERSATIONS}`,
  USERS: `/${RouteParts.USERS}/:userId`,
  VERSION: '/version',
  NO_RESULTS: '/no-results',
  NOT_FOUND: `${RouteParts.WILDCARD}`,
};

export const AuthRoutes = {
  AUTH_CALLBACK: '/auth/callback',
  ERROR: '/error',
};
